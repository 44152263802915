import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          terms: {
            header: "Terms and Conditions",
            description:
              "Thank you for choosing our web or mobile app for your travel needs. Please read the following terms and conditions carefully before using our services:",
            h1: "Acceptance of Terms :",
            p1:
              "By using our services, you agree to be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, you may not use our services.",
            h2: "Eligibility :",
            p2:
              "You must be at least 18 years old to use our services. By using our services, you represent and warrant that you are at least 18 years old.",
            h3: "Service Description :",
            p3:
              "Our web or mobile app booking services provide a platform to connect users with TURSAB approved, travel agency powered services. Our services are intended to be used for commercial use only.",
            h4: "Payment & Refund:",
            p4:
              "Users are required to pay for services provided through our platform. We accept various forms of payment, including credit cards, debit cards, and electronic payment methods. All payments made through our platform are subject to our payment policies, which may be updated from time to time. We do refunds for free up to 60 minutes before the agreed pickup time. We don't do refunds for less than 60 minutes before the agreed pickup time",
            h5: "User Conduct :",
            p5:
              "Users are expected to use our services responsibly and not engage in any behavior that may harm our platform, empleyees, or other users. Users are not permitted to use our platform for any unlawful or unauthorized purpose. Users are also prohibited from interfering with the operation of our platform or engaging in any activity that could compromise the security or integrity of our platform.",
            h6: "User Content :",
            p6:
              "Users are solely responsible for the content they submit through our platform. By submitting content, users grant us a non-exclusive, royalty-free, transferable, and sublicensable license to use, modify, and reproduce the content for the purpose of providing our services.",
            h7: "Intellectual Property :",
            p7:
              "Our platform, including all content and intellectual property, is owned by us and/or our licensors. Users may not copy, modify, distribute, or reproduce any of the content or intellectual property without our prior written consent.",
            h8: "Disclaimers :",
            p8:
              "We do not guarantee the availability, reliability, or quality of any services provided by drivers using our platform. We are not responsible for any loss or damage that may result from the use of our services. Our platform is provided 'as is' and without any warranties, express or implied.",
            h9: "Limitation of Liability :",
            p9:
              "In no event shall we be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of our services, whether or not we have been advised of the possibility of such damages.",
            h10: "Termination :",
            p10:
              "We reserve the right to terminate a users access to our platform at any time, without notice, for any reason.",
            h11: "Governing Law :",
            p11:
              "These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction where the company is based.",
            h12: "Amendments :",
            p12:
              "We reserve the right to update these Terms and Conditions at any time, without notice. Users are advised to review these Terms and Conditions periodically to stay informed of any changes.If you have any questions or concerns about these Terms and Conditions, please contact us at oncu@zar.com.tr",
          },
          privacy: {
            header: "Privacy Policy",
            description:
              "This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use any of our Service.",
            h1: "Acceptance of Terms :",
            p1:
              "If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at our all Apps unless otherwise defined in this Privacy Policy.",
            h2: "Information collection and use:",
            p2:
              "For a better experience, while using our Service, we will require you to provide us with certain personally identifiable information, including but not limited to First Name, Last Name, Email Address, Phone Number The information that we request will be retained by us and used as described in this privacy policy. We do collect the following sensitive information when you use or Sign up on our App. We collect your location data to enable The app use third party services for social login like Google and Apple Login that collect information used to identify you. We capture User email and Name from social login if user has chosen it to be disclosed while performing a social login.",
            h3: "Log Data :",
            p3:
              "We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.",
            h4: "Cookies:",
            p4:
              "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.",
            h5: "Service Providers:",
            p5:
              "We may employ third-party companies and individuals due to the following reasons: To facilitate our Service; To provide the Service on our behalf; To perform Service-related services; or To assist us in analyzing how our Service is used. We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.",
            h6: "Security:",
            p6:
              "We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.",
            h7: "Link to Other Sites:",
            p7:
              "This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.",
            h8: "Childeren's Privacy :",
            p8:
              "These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.",
            h9: "Delete Account",
            p9:
              "User can remove all their data from the system by deleting the account from profile page in mobile and web app.Once you delete your account All your data will be purged from the system. Your profile image, email, phone number, social logins including Google login and all booking history, everything will be permanently removed. Deleted user data and account are irrecoverable.",
            h10: "Changes to this Ptivacy Policy :",
            p10:
              "We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. This policy is effective as of 01-01-2025",
            h11: "Contact Us :",
            p11:
              "If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at oncu@zar.com.tr.",
          },
          menu: {
            home: "Home",
            terms: "Terms and Conditions",
          },
          product: {
            viewDetails: "View Details",
            priceFrom: "from ",
            May: "May",
            June: "June",
            July: "July",
            August: "August",
            September: "September",
            October: "October",
          },

          reservation: {
            bookingMessage: "Booking Request from Bodrum.boats",
            makeareservation: "Make a Reservation",
            startDate: "Start Date",
            endDate: "End Date",
            tourDate: "Tour Date",
            tourName: "Tour Name",
            tourType: "Tour Type",
            tourPrice: "Tour Price",
            adults: "Adults",
            children: "Children",
            guests: "Guests",
            bookNow: "Book Now via Whatsapp!",
            ischarter: "Charter?",
            person: "Person",
            addBasket: "Add to Basket",
            pleaseSelect: "Please Select",

            payWhatsapp: "Complete order by Whatsapp",
            payDeposit: "Pay Deposit via Credit Card",
            payAll: "Pay via Credit Card",
            deposit: "Deposit",
          },
          amenities: {
            boatDetails: "Boat Details",
            boatType: "Boat Type",
            lenght: "Lenght",
            numberofCabins: "Number of Cabins",
            engine: "Engine",
          },
          pictures: {
            viewPhotos: "View Photos",
          },
          topBar: {
            overview: "Overview",
            details: "Details",
            reservation: "Reservation",
            boatDetails: "Boat Details",
            boatPrices: "Boat Prices",
            contactUs: "Contact Us",
            share: "Share",
          },
          tour: {
            prices: "Prices",
          },
          checkout: {
            cardHolder: "Card Holder",
            cardNumber: "Card Number",
            expires: "Expires",
            month: " Month",
            year: "Year",
            fullName: "NAME SURNAME",
            tourInformation: "Tour Information",
            basicInformation: "Basic Information",
            billingInformation: "Billing Information",
            paymentInformation: "Payment Information",
            identity: "Identity Number",
            date: "Tour Date",
            name: "Name",
            surname: "Surname",

            contactName: "Name and Surname",
            email: "Email",
            address: "Address",
            country: "Country",
            city: "City",
            state: "State",
            zipCode: "Zip Code",
            pHcontactName: "Please enter your Name and Surname",
            pHname: "Please enter your Name ",
            pHdate: "Please select tour date ",
            pHsurname: "Please enter your Surname",
            pHemail: "Please enter email address",
            pHaddress: "Please enter your Address",
            pHcountry: "Please enter your Country",
            pHcity: "Please enter your City",
            pHzipCode: "Please enter your Zip Code",
            pHstate: "Please enter your State",
            pHIdentity: "Please enter your Identity Number",
            checkIdentity: "Please check your Identity Number",
            checkname: "Please check your Name",
            checkcontactName: "Please check your Name and Surname",
            checkaddress: "Please check your Address",
            checkcountry: "Please check your Country",
            checkcity: "Please check your City",
            checkstate: "Please check your State",
            saveButton: "Save Address and Continue",
            addacard: "Add a Card",

            checkCreditCard: "Please check your credit card number",
            checkExpiricy: "Please check your expiricy date",
            checkCvc: "Please check your cvc",
            checkName: "Please check your name and surname",
            checkEmail: "Please check your email address",
            checkZipCode: "Please check your Zipcode",
            continue: "Add Card and Continue",
            placeOrder: "Place Order",
            paymentMethod: "Payment Method",
            orderPlaced: "Order Placed",
            orderPlacedHeader: "Congratulations!",
            orderPlacedDescription: "Your order has been placed successfully.",
            next: "Continue",
            back: "Back",
            pleaseSelectService: "Please Select Your Service",
            orderSummary: "Order Summary",
            pay: "Pay",
            orderReceived: "Your Order has been placed",
            orderStatus: "Order Status",
            error: "Error",

            phone: "Phone Number",
            pHphone: "Please enter Your Phone",
            checkPhone: "Please check your phone number",
            hotel: "Hotel Name",
            pHhotel: "Please enter Your Hotel Name",
            checkHotel: "Please check your hotel name",
            room: "Room Number",
            pHroom: "Please enter Your Room Number",
            checkRoom: "Please check your room number",
          },
        },
      },
      tr: {
        translation: {
          terms: {
            header: "Şartlar ve Koşullar",
            description:
              "Seyahat ihtiyaçlarınız için web veya mobil uygulamamızı seçtiğiniz için teşekkür ederiz. Lütfen hizmetlerimizi kullanmadan önce aşağıdaki şartları ve koşulları dikkatlice okuyun:",
            h1: "Şartların Kabulü :",
            p1:
              "Hizmetlerimizi kullanarak, bu Şartlar ve Koşullara bağlı kalmayı kabul edersiniz. Bu Şartlar ve Koşulları kabul etmiyorsanız, hizmetlerimizi kullanamazsınız.",
            h2: "Uygunluk :",
            p2:
              "Hizmetlerimizi kullanmak için en az 18 yaşında olmanız gerekir. Hizmetlerimizi kullanarak, en az 18 yaşında olduğunuzu beyan ve garanti edersiniz.",
            h3: "Hizmet Açıklaması :",
            p3:
              "Web veya mobil uygulama rezervasyon hizmetlerimiz, kullanıcıları TURSAB onaylı, seyahat acentesi destekli hizmetlerle buluşturmak için bir platform sağlar. Hizmetlerimiz yalnızca ticari amaçlı kullanılmak üzere tasarlanmıştır.",
            h4: "Ödeme ve İade:",
            p4:
              "Kullanıcıların hizmetler için ödeme yapması gerekir platformumuz aracılığıyla sağlanır. Kredi kartları, banka kartları ve elektronik ödeme yöntemleri dahil olmak üzere çeşitli ödeme biçimlerini kabul ediyoruz. Platformumuz aracılığıyla yapılan tüm ödemeler, zaman zaman güncellenebilecek ödeme politikalarımıza tabidir. Anlaşılan teslim alma saatinden 60 dakika öncesine kadar ücretsiz geri ödeme yapıyoruz. Anlaşılan teslim alma saatinden 60 dakikadan daha kısa bir süre önce geri ödeme yapmıyoruz",
            h5: "Kullanıcı Davranışı :",
            p5:
              "Kullanıcıların hizmetlerimizi sorumlu bir şekilde kullanmaları ve platformumuza, çalışanlarımıza veya diğer kullanıcılara zarar verebilecek hiçbir davranışta bulunmamaları beklenmektedir. Kullanıcıların platformumuzu herhangi bir yasa dışı veya yetkisiz amaç için kullanmalarına izin verilmez. Kullanıcıların ayrıca platformumuzun işleyişine müdahale etmeleri veya platformumuzun güvenliğini veya bütünlüğünü tehlikeye atabilecek herhangi bir faaliyette bulunmaları yasaktır.",
            h6: "Kullanıcı İçeriği :",
            p6:
              "Kullanıcılar, platformumuz aracılığıyla gönderdikleri içerikten yalnızca kendileri sorumludur. İçerik göndererek, kullanıcılar bize hizmetlerimizi sağlama amacıyla içeriği kullanma, değiştirme ve çoğaltma konusunda münhasır olmayan, telifsiz, devredilebilir ve alt lisanslanabilir bir lisans verir.",
            h7: "Fikri Mülkiyet :",
            p7:
              "Tüm içerik ve fikri mülkiyet dahil olmak üzere platformumuz bize ve/veya lisans verenlerimize aittir. Kullanıcılar önceden yazılı iznimiz olmadan içeriğin veya fikri mülkiyetin hiçbirini kopyalayamaz, değiştiremez, dağıtamaz veya çoğaltamaz.",
            h8: "Sorumluluk Reddi :",
            p8:
              "Sürücülerin platformumuzu kullanarak sağladığı hiçbir hizmetin kullanılabilirliğini, güvenilirliğini veya kalitesini garanti etmiyoruz. Hizmetlerimizin kullanımından kaynaklanabilecek herhangi bir kayıp veya hasardan sorumlu değiliz. Platformumuz 'olduğu gibi' ve açık veya zımni hiçbir garanti olmaksızın sağlanmaktadır.",
            h9: "Sorumluluğun Sınırlandırılması :",
            s9:
              "Hizmetlerimizin kullanımıyla bağlantılı veya bunlardan kaynaklanan dolaylı, tesadüfi, özel veya sonuçsal zararlardan, bu tür zararların olasılığı konusunda bilgilendirilmiş olsak da olmasak da, hiçbir durumda sorumlu olmayacağız.",
            h10: "Fesih :",
            s10:
              "Herhangi bir zamanda, bildirimde bulunmaksızın, herhangi bir nedenle, bir kullanıcının platformumuza erişimini sonlandırma hakkını saklı tutarız.",
            h11: "Yürürlükteki Kanun :",
            s11:
              "Bu Şartlar ve Koşullar, şirketin merkezinin bulunduğu yargı bölgesinin yasalarına tabi olacak ve bu yasalara uygun şekilde yorumlanacaktır.",
            h12: "Değişiklikler :",
            s12:
              "Bu Şartlar ve Koşulları herhangi bir zamanda, bildirimde bulunmaksızın güncelleme hakkını saklı tutarız. Kullanıcıların, herhangi bir değişiklikten haberdar olmak için bu Şartlar ve Koşulları düzenli olarak gözden geçirmeleri önerilir. Bu Şartlar ve Koşullar hakkında herhangi bir sorunuz veya endişeniz varsa lütfen oncu@zar.com.tr adresinden bizimle iletişime geçin",
          },
          privacy: {
            header: "Gizlilik Politikası",
            description:
              "Bu sayfa, herhangi birinin Hizmetimizden herhangi birini kullanmaya karar vermesi durumunda Kişisel Bilgilerin toplanması, kullanımı ve ifşasıyla ilgili politikalarımız hakkında ziyaretçileri bilgilendirmek için kullanılır.",
            h1: "Şartların Kabulü :",
            p1:
              "Hizmetimizi kullanmayı seçerseniz, bu politikayla ilgili bilgilerin toplanmasını ve kullanılmasını kabul etmiş olursunuz. Topladığımız Kişisel Bilgiler, Hizmeti sağlamak ve iyileştirmek için kullanılır. Bilgilerinizi bu Gizlilik Politikasında açıklanan durumlar dışında kimseyle kullanmayacak veya paylaşmayacağız. Bu Gizlilik Politikasında kullanılan terimler, aksi bu Gizlilik Politikasında tanımlanmadığı sürece tüm Uygulamalarımızda erişilebilen Şartlar ve Koşullarımızdaki anlamlarla aynıdır.",
            h2: "Bilgi toplama ve kullanımı:",
            p2:
              "Daha iyi bir deneyim için, Hizmetimizi kullanırken, Adınız, Soyadınız, E-posta Adresiniz, Telefon Numaranız dahil ancak bunlarla sınırlı olmamak üzere, belirli kişisel olarak tanımlanabilir bilgileri bize sağlamanızı isteyeceğiz. İstediğimiz bilgiler tarafımızca saklanacak ve bu gizlilik politikasında açıklandığı şekilde kullanılacaktır. Uygulamamızı kullandığınızda veya Uygulamamıza kaydolduğunuzda aşağıdaki hassas bilgileri topluyoruz. Uygulamanın sizi tanımlamak için kullanılan bilgileri toplayan Google ve Apple Girişi gibi sosyal oturum açma için üçüncü taraf hizmetlerini kullanmasını sağlamak için konum verilerinizi topluyoruz. Kullanıcı sosyal oturum açma işlemi gerçekleştirirken ifşa edilmesini seçtiyse, sosyal oturum açmadan Kullanıcı e-postasını ve Adını yakalıyoruz.",
            h3: "Günlük Verileri :",
            p3:
              "Hizmetimizi her kullandığınızda, uygulamada bir hata olması durumunda telefonunuzda Günlük Verileri adı verilen verileri ve bilgileri (üçüncü taraf ürünler aracılığıyla) topladığımızı bildirmek isteriz. Bu Günlük Verileri, cihazınızın İnternet Protokolü ('IP') adresi, cihaz adı, işletim sistemi sürümü, Hizmetimizi kullanırken uygulamanın yapılandırması, Hizmeti kullanımınızın saati ve tarihi ve diğer istatistikler gibi bilgileri içerebilir.",
            h4: "Çerezler:",
            p4:
              "Çerezler, genellikle anonim benzersiz tanımlayıcılar olarak kullanılan az miktarda veri içeren dosyalardır. Bunlar, ziyaret ettiğiniz web sitelerinden tarayıcınıza gönderilir ve cihazınızın dahili belleğinde saklanır. Bu Hizmet bu çerezleri açıkça kullanmaz. Ancak uygulama, bilgi toplamak ve hizmetlerini iyileştirmek için çerezler kullanan üçüncü taraf kodlarını ve kitaplıklarını kullanabilir. Bu çerezleri kabul etme veya reddetme ve cihazınıza bir çerez gönderildiğinde bunu bilme seçeneğiniz vardır. Çerezlerimizi reddetmeyi seçerseniz, bu Hizmetin bazı bölümlerini kullanamayabilirsiniz.",
            h5: "Hizmet Sağlayıcıları:",
            p5:
              "Aşağıdaki nedenlerden dolayı üçüncü taraf şirketleri ve kişileri istihdam edebiliriz: Hizmetimizi kolaylaştırmak için; Hizmeti bizim adımıza sağlamak için; Hizmetle ilgili hizmetleri gerçekleştirmek; veya Hizmetimizin nasıl kullanıldığını analiz etmemize yardımcı olmak. Bu Hizmetin kullanıcılarını, bu üçüncü tarafların Kişisel Bilgilerinize erişebildiği konusunda bilgilendirmek istiyoruz. Bunun nedeni, kendilerine atanan görevleri bizim adımıza yerine getirmektir. Ancak, bilgileri başka bir amaçla ifşa etmemek veya kullanmamakla yükümlüdürler.",
            h6: "Güvenlik:",
            p6:
              "Kişisel Bilgilerinizi bize sağlama konusunda güveninize değer veriyoruz, bu nedenle bunları korumak için ticari olarak kabul edilebilir yollar kullanmaya çalışıyoruz. Ancak, internet üzerinden hiçbir iletim yönteminin veya elektronik depolama yönteminin %100 güvenli ve güvenilir olmadığını ve mutlak güvenliğini garanti edemeyeceğimizi unutmayın.",
            h7: "Diğer Sitelere Bağlantı:",
            p7:
              "Bu Hizmet, diğer sitelere bağlantılar içerebilir. Üçüncü taraf bir bağlantıya tıklarsanız, o siteye yönlendirileceksiniz. Bu harici sitelerin bizim tarafımızdan işletilmediğini unutmayın. Bu nedenle, bu web sitelerinin Gizlilik Politikasını incelemenizi şiddetle tavsiye ederiz. Üçüncü taraf sitelerin veya hizmetlerin içeriği, gizlilik politikaları veya uygulamaları üzerinde hiçbir kontrolümüz yoktur ve hiçbir sorumluluk kabul etmiyoruz.",
            h8: "Çocukların Gizliliği :",
            p8:
              "Bu Hizmetler 13 yaşından küçük hiç kimseye hitap etmemektedir. 13 yaşından küçük çocuklardan bilerek kişisel olarak tanımlanabilir bilgi toplamıyoruz. 13 yaşından küçük bir çocuğun bize kişisel bilgi verdiğini tespit etmemiz durumunda, bunu derhal sunucularımızdan sileriz. Ebeveyn veya veliyseniz ve çocuğunuzun bize kişisel bilgi verdiğini biliyorsanız, lütfen gerekli işlemleri yapabilmemiz için bizimle iletişime geçin.",
            h9: "Hesabı Sil",
            p9:
              "Kullanıcı, mobil ve web uygulamasındaki profil sayfasından hesabı silerek tüm verilerini sistemden kaldırabilir. Hesabınızı sildiğinizde tüm verileriniz sistemden temizlenecektir. Profil resminiz, e-postanız, telefon numaranız, Google girişi dahil sosyal oturum açma bilgileriniz ve tüm rezervasyon geçmişiniz, her şey kalıcı olarak kaldırılacaktır. Silinen kullanıcı verileri ve hesap kurtarılamaz.",
            h10: "Bu Gizlilik Politikasındaki Değişiklikler :",
            p10:
              "Gizlilik Politikamızı zaman zaman güncelleyebiliriz. Bu nedenle, herhangi bir değişiklik olup olmadığını görmek için bu sayfayı düzenli olarak incelemeniz önerilir. Bu sayfada yeni Gizlilik Politikasını yayınlayarak sizi herhangi bir değişiklikten haberdar edeceğiz. Bu politika 01-01-2025 tarihinden itibaren geçerlidir.",
            h11: "Bizimle İletişime Geçin :",
            p11:
              "Gizlilik Politikamız hakkında herhangi bir sorunuz veya öneriniz varsa, oncu@zar.com.tr adresinden bizimle iletişime geçmekten çekinmeyin.",
          },
          menu: {
            home: "Anasayfa",
            terms: "Şartlar ve Koşullar",
          },
          product: {
            viewDetails: "Detayları Gör",
            priceFrom: "Başlangıç fiyatı: ",
            May: "Mayıs",
            June: "Haziran",
            July: "Temmuz",
            August: "Ağustos",
            September: "Eylül",
            October: "Ekim",
          },
          reservation: {
            bookingMessage: "Bodrum.boats'tan Rezervasyon Talebi",
            makeareservation: "Rezervasyon Yap",
            startDate: "Başlangıç ​​tarihi",
            endDate: "Bitiş tarihi",
            tourDate: "Tur Tarihi",
            tourName: "Tur Adı",
            tourType: "Tur Türü",
            adults: "Yetişkinler",
            children: "Çocuklar",
            guests: "Misafirler",
            bookNow: "Whatsapp ile Rezervasyon Yapın!",
            person: "Kişi",
            addBasket: "Sepete Ekleyin",
            pleaseSelect: "Lütfen Seçiniz",
            payWhatsapp: "Siparişi Whatsapp ile tamamla",
            payDeposit: "Kredi Kartı ile Depozito öde",
            payAll: "Kredi Kartı ile Tamamını öde",
            deposit: "Depozito",
          },
          amenities: {
            boatDetails: "Tekne Detayları",
            boatType: "Tekne Tipi",
            lenght: "Uzunluk",
            numberofCabins: "Kabin Sayısı",
            engine: "Motor Gücü",
          },
          pictures: {
            viewPhotos: "Fotoğrafları Gör",
          },
          topBar: {
            overview: "Genel",
            boatDetails: "Tekne Detayları",
            boatPrices: "Tekne Fiyatları",
            contactUs: "Bize Ulaşın",
            share: "Paylaş",
          },
          checkout: {
            cardHolder: "Kart Sahibi",
            cardNumber: "Kart Numarası",
            expires: "Son Kullanma",
            month: "Ay",
            year: "Yıl",
            fullName: "ADI SOYADI",
            tourInformation: "Tur Bilgileri",
            basicInformation: "Genel Bilgileri",
            billingInformation: "Fatura Bilgileri",
            paymentInformation: "Ödeme Bilgileri",
            identity: "Kimlik Numarası",
            date: "Tur Tarihi",
            name: "Adı",
            surname: "Soyadı",
            contactName: "Adı ve Soyadı",
            email: "Email",
            address: "Adres",
            country: "Ülke",
            city: "Şehir",
            state: "State",
            zipCode: "Posta Kodu",
            pHcontactName: "Lütfen Ad ve Soyadınızı girin",
            pHname: "Lütfen Adınızı Girin ",
            pHdate: "Lütfen Tur Tarihini Seçin ",
            pHsurname: "Lütfen Soyadınızı Girin",
            pHemail: "Lütfen Email Adresinizi Girin",
            pHaddress: "Lütfen Adresinizi Girin",
            pHcountry: "Lütfen Ülkenizi Girin",
            pHcity: "Lütfen Şehrinizi Girin",
            pHzipCode: "Lütfen Posta Kodumnuzu Girin",
            pHstate: "Lütfen State Girin",
            pHIdentity: "Lütfen Kimlik Numaranızı Girin",
            checkIdentity: "Lütfen Kimlik Numaranızı Kontrol Edin",
            checkname: "Lütfen Adınızı Kontrol Edin",
            checkcontactName: "Lütfen Ad ve Soyadınızı Kontrol Edin",
            checkaddress: "Lütfen Adresinizi Kontrol Edin",
            checkcountry: "Lütfen Ülkenizi Kontrol Edin",
            checkcity: "Lütfen Şehrinizi Kontrol Edin",
            checkstate: "Lütfen State Kontrol Edin",
            checkZipCode: "Lütfen Posta Kodunuzu Kontrol Edin",
            saveButton: "Adresi Kaydet ve Detam Et",
            addacard: "Kart Ekleyin",

            checkCreditCard: "Lütfen Kredi Kartı Numaranızı Kontrol Edin",
            checkExpiricy: "Lütfen Son Kullanma Tarihinizi Kontrol Edin",
            checkCvc: "Lütfen CVC Kontrol Edin",

            checkEmail: "Lütfen Email Adresinizi Kontrol Edin",
            continue: "Kartı Kaydet ve Detam Et",
            placeOrder: "Sipariş Oluştur",
            paymentMethod: "Ödeme Yöntemi",
            orderPlaced: "Sipariş Oluşturuldu",
            orderPlacedHeader: "Tebrikler!",
            orderPlacedDescription: "Siparişiniz Başarı ile Oluşturuldu.",
            next: "Devam et",
            back: "Geri",
            pleaseSelectService: "Lütfen Servisi Seçiniz",
            orderSummary: "Sipariş Özeti",
            pay: "Öde",
            orderReceived: "Siparişiniz Oluşturuldu",
            orderStatus: "sipariş Durumu",
            error: "Hata",

            phone: "Telefon Numaranız",
            pHphone: "Lütfen Telefon Numaranızı Girin",
            checkPhone: "Lütfen Telefon Numaranızı Kontrol Edin",
            hotel: "Otel Adınız",
            pHhotel: "Otel Adınızı Girin",
            checkHotel: "Otel Adınızı kontrol edin",
            room: "Oda numaranız",
            pHroom: "Lütfen Oda numaranızı girin",
            checkRoom: "Lütfen oda numaranızı kontrol edin",
          },
          tour: {
            prices: "Fiyatlar",
          },
        },
      },
      fr: {
        translation: {
          terms: {
            header: "Termes et conditions",
            description:
              "Merci d'avoir choisi notre application Web ou mobile pour vos besoins de voyage. Veuillez lire attentivement les conditions générales suivantes avant d'utiliser nos services :",
            h1: "Acceptation des conditions :",
            p1:
              "En utilisant nos services, vous acceptez d'être lié par ces conditions générales. Si vous n'acceptez pas ces conditions générales, vous ne pouvez pas utiliser nos services.",
            h2: "Éligibilité :",
            p2:
              "Vous devez avoir au moins 18 ans pour utiliser nos services. En utilisant nos services, vous déclarez et garantissez que vous avez au moins 18 ans.",
            h3: "Description du service :",
            p3:
              "Nos services de réservation Web ou application mobile fournissent une plate-forme pour connecter les utilisateurs aux services approuvés par TURSAB et alimentés par des agences de voyages. Nos services sont destinés à être utilisés à des fins commerciales uniquement.",
            h4: "Paiement et remboursement :",
            p4:
              "Les utilisateurs sont tenus de payer pour les services fournis via notre plateforme. Nous acceptons différentes formes de paiement, notamment les cartes de crédit, les cartes de débit et les méthodes de paiement électronique. Tous les paiements effectués via notre plateforme sont soumis à nos politiques de paiement, qui peuvent être mises à jour de temps à autre. Nous effectuons des remboursements gratuitement jusqu'à 60 minutes avant l'heure de retrait convenue. Nous n'effectuons pas de remboursement moins de 60 minutes avant l'heure de retrait convenue",
            h5: "Conduite de l'utilisateur :",
            p5:
              "Les utilisateurs sont tenus d'utiliser nos services de manière responsable et de ne pas adopter de comportement susceptible de nuire à notre plateforme, à nos employés ou à d'autres utilisateurs. Les utilisateurs ne sont pas autorisés à utiliser notre plateforme à des fins illégales ou non autorisées. Il est également interdit aux utilisateurs d'interférer avec le fonctionnement de notre plateforme ou de s'engager dans toute activité susceptible de compromettre la sécurité ou l'intégrité de notre plateforme.",
            h6: "Contenu utilisateur :",
            p6:
              "Les utilisateurs sont seuls responsables du contenu qu'ils soumettent via notre plateforme. En soumettant du contenu, les utilisateurs nous accordent une licence non exclusive, libre de redevances, transférable et sous-licenciable pour utiliser, modifier et reproduire le contenu dans le but de fournir nos services.",
            h7: "Propriété intellectuelle :",
            p7:
              "Notre plateforme, y compris tout le contenu et la propriété intellectuelle, nous appartient et/ou à nos concédants de licence. Les utilisateurs ne peuvent pas copier, modifier, distribuer ou reproduire le contenu ou la propriété intellectuelle sans notre consentement écrit préalable.",
            h8: "Avertissements :",
            p8:
              "Nous ne garantissons pas la disponibilité, la fiabilité ou la qualité des services fournis par les conducteurs utilisant notre plateforme. Nous ne sommes pas responsables de toute perte ou dommage pouvant résulter de l'utilisation de nos services. Notre plateforme est fournie telle quelle et sans aucune garantie, expresse ou implicite.",
            h9: "Limitation de responsabilité :",
            p9:
              "En aucun cas, nous ne serons responsables de dommages indirects, accessoires, spéciaux ou consécutifs découlant de ou en relation avec l'utilisation de nos services, que nous ayons ou non été informés de la possibilité de tels dommages.",
            h10: "Résiliation :",
            p10:
              "Nous nous réservons le droit de résilier l'accès d'un utilisateur à notre plateforme à tout moment, sans préavis, pour quelque raison que ce soit.",
            h11: "Loi applicable :",
            p11:
              "Ces conditions générales seront régies et interprétées conformément aux lois de la juridiction où la société est basée.",
            h12: "Modifications :",
            p12:
              "Nous nous réservons le droit de mettre à jour ces conditions générales à tout moment, sans préavis. Il est conseillé aux utilisateurs de consulter régulièrement ces conditions générales pour rester informés de tout changement. Si vous avez des questions ou des préoccupations concernant ces conditions générales, veuillez nous contacter à l'adresse oncu@zar.com.tr",
          },
          confidentialité: {
            header: "Politique de confidentialité",
            description:
              "Cette page est utilisée pour informer les visiteurs de nos politiques en matière de collecte, d'utilisation et de divulgation des informations personnelles si quelqu'un décide d'utiliser l'un de nos services.",
            h1: "Acceptation des conditions : ",
            p1:
              "Si vous choisissez d'utiliser notre service, vous acceptez la collecte et l'utilisation des informations en relation avec cette politique. Les informations personnelles que nous collectons sont utilisées pour fournir et améliorer le service. Nous n'utiliserons ni ne partagerons vos informations avec quiconque, sauf comme décrit dans la présente politique de confidentialité. Les termes utilisés dans la présente politique de confidentialité ont la même signification que dans nos conditions générales, qui sont accessibles sur toutes nos applications, sauf définition contraire dans la présente politique de confidentialité.",
            h2: "Collecte et utilisation des informations : ",
            p2:
              "Pour une meilleure expérience, lors de l'utilisation de notre service, nous vous demanderons de nous fournir certaines informations personnellement identifiables, y compris, mais sans s'y limiter, le prénom, le nom, l'adresse e-mail et le numéro de téléphone. Les informations que nous demandons seront conservées par nous et utilisées comme décrit dans la présente politique de confidentialité. Nous collectons les informations sensibles suivantes lorsque vous utilisez ou vous inscrivez sur notre application. Nous collectons vos données de localisation pour permettre à l'application d'utiliser des services tiers pour la connexion sociale comme Google et Apple Login qui collectent des informations utilisées pour vous identifier. Nous capturons l'e-mail et le nom de l'utilisateur à partir de la connexion sociale si l'utilisateur a choisi de les divulguer lors de l'exécution d'une connexion sociale.",
            h3: "Données de journal :",
            p3:
              "Nous souhaitons vous informer que chaque fois que vous utilisez notre service, en cas d'erreur dans l'application, nous collectons des données et des informations (via des produits tiers) sur votre téléphone appelées données de journal. Ces données de journal peuvent inclure des informations telles que l'adresse IP (Internet Protocol) de votre appareil, le nom de l'appareil, la version du système d'exploitation, la configuration de l'application lors de l'utilisation de notre service, l'heure et la date de votre utilisation du service et d'autres statistiques.",
            h4: "Cookies :",
            p4:
              "Les cookies sont des fichiers contenant une petite quantité de données qui sont couramment utilisés comme identifiants uniques anonymes. Ceux-ci sont envoyés à votre navigateur à partir des sites Web que vous visitez et sont stockés dans la mémoire interne de votre appareil. Ce service n'utilise pas explicitement ces cookies. Cependant, l'application peut utiliser du code et des bibliothèques tiers qui utilisent des cookies pour collecter des informations et améliorer leurs services. Vous avez la possibilité d'accepter ou de refuser ces cookies et de savoir quand un cookie est envoyé sur votre appareil. Si vous choisissez de refuser nos cookies, vous ne pourrez peut-être pas utiliser certaines parties de ce service.",
            h5: "Fournisseurs de services :",
            p5:
              "Nous pouvons employer des sociétés et des personnes tierces pour les raisons suivantes : Pour faciliter notre service ; Pour fournir le service en notre nom ; Pour exécuter des services liés au service ; ou Pour nous aider à analyser la façon dont notre service est utilisé. Nous souhaitons informer les utilisateurs de ce service que ces tiers ont accès à vos informations personnelles. La raison est d'effectuer les tâches qui leur sont assignées en notre nom. Cependant, ils sont tenus de ne pas divulguer ni utiliser les informations à d'autres fins.",
            h6: "Sécurité :",
            p6:
              "Nous apprécions la confiance que vous nous accordez en nous fournissant vos informations personnelles, c'est pourquoi nous nous efforçons d'utiliser des moyens commercialement acceptables pour les protéger. Mais n'oubliez pas qu'aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est sûre et fiable à 100 %, et nous ne pouvons pas garantir sa sécurité absolue.",
            h7: "Lien vers d'autres sites :",
            p7:
              "Ce service peut contenir des liens vers d'autres sites. Si vous cliquez sur un lien tiers, vous serez redirigé vers ce site. Notez que ces sites externes ne sont pas exploités par nous. Par conséquent, nous vous conseillons vivement de consulter la politique de confidentialité de ces sites Web. Nous n'avons aucun contrôle sur le contenu, les politiques de confidentialité ou les pratiques de tout site ou service tiers et n'assumons aucune responsabilité à leur égard.",
            h8: "Confidentialité des enfants :",
            p8:
              "Ces services ne s'adressent pas aux personnes de moins de 13 ans. Nous ne collectons pas sciemment d'informations personnelles identifiables auprès d'enfants de moins de 13 ans. Dans le cas où nous découvririons qu'un enfant de moins de 13 ans nous a fourni des informations personnelles, nous les supprimons immédiatement de nos serveurs. Si vous êtes un parent ou un tuteur et que vous savez que votre enfant nous a fourni des informations personnelles, veuillez nous contacter afin que nous puissions prendre les mesures nécessaires.",
            h9: "Supprimer le compte",
            p9:
              " L'utilisateur peut supprimer toutes ses données du système en supprimant le compte de la page de profil dans l'application mobile et Web. Une fois que vous avez supprimé votre compte, toutes vos données seront purgées du système. Votre image de profil, votre e-mail, votre numéro de téléphone, vos identifiants sociaux, y compris votre identifiant Google et tout l'historique des réservations, tout sera définitivement supprimé. Les données utilisateur et le compte supprimés sont irrécupérables. ",
            h10: " Modifications de cette politique de confidentialité : ",
            p10:
              " Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Ainsi, il vous est conseillé de consulter cette page périodiquement pour tout changement. Nous vous informerons de tout changement en publiant la nouvelle politique de confidentialité sur cette page. Cette politique est en vigueur à compter du 01-01-2025 ",
            h11: " Nous contacter : ",
            p11:
              " Si vous avez des questions ou des suggestions concernant notre politique de confidentialité, n'hésitez pas à nous contacter à l'adresse oncu@zar.com.tr. ",
          },
          menu: {
            home: "Accueil",
            terms: "Termes et Conditions",
          },
          product: {
            viewDetails: "Voir les détails",
            priceFrom: "depuis ",
            May: "Mai",
            June: "Juin",
            July: "Juillet",
            August: "Août",
            September: "Septembre",
            October: "Octobre",
          },
          reservation: {
            bookingMessage: "Demande de réservation de Bodrum.boats",
            makeareservation: "Faire une réservation",
            startDate: "Date de début",
            endDate: "Date de fin",
            tourDate: "Date de la tournée",
            tourName: "Nom de la tournée",
            tourType: "Type de visite",
            tourPrice: "Prix ​​du voyage",
            adults: "Adultes",
            children: "Enfants",
            guests: "Invités",
            bookNow: "Réservez maintenant via Whatsapp !",
            ischarter: "Charte?",
            person: "Personne",
            addBasket: "ajouter au panier",
            pleaseSelect: "Veuillez sélectionner",
            payWhatsapp: "Terminer la commande par Whatsapp",
            payDeposit: "Payer le dépôt par carte de crédit",
            payAll: "Payer par carte de crédit",
            deposit: "Dépôt",
          },
          amenities: {
            boatDetails: "Détails du bateau",
            boatType: "Type de bateau",
            lenght: "Longueur",
            numberofCabins: "Nombre de cabines",
            engine: "Moteur",
          },
          pictures: {
            viewPhotos: "Voir les photos",
          },
          topBar: {
            overview: "Aperçu",
            details: "Détails",
            reservation: "Réservation",
            boatDetails: "Détails du bateau",
            boatPrices: "Tarifs des bateaux",
            contactUs: "Contactez-nous",
            share: "Partager",
          },
          tour: {
            prices: "Des prix",
          },
          checkout: {
            cardHolder: "Titulaire de la carte",
            cardNumber: "Numéro de carte",
            expires: "Expire",
            month: " Mois",
            year: "Année",
            fullName: "PRÉNOM / NOM DE FAMILLE",
            tourInformation: "Informations sur la visite",
            basicInformation: "Informations de base",
            billingInformation: "Informations de facturation",
            paymentInformation: "Informations de paiement",
            identity: "Numéro d'identité",
            date: "Date de la tournée",
            name: "Nom",
            surname: "Nom de famille",
            contactName: "Nom et surnom",
            email: "E-mail",
            address: "Adresse",
            country: "Pays",
            city: "Ville",
            state: "État",
            zipCode: "Code postal",
            pHcontactName: "Veuillez entrer votre nom et prénom",
            pHname: "S'il vous plaît entrez votre nom ",
            pHdate: "Veuillez sélectionner la date de la visite ",
            pHsurname: "Veuillez entrer votre nom de famille",
            pHemail: "Veuillez entrer l'adresse e-mail",
            pHaddress: "Veuillez entrer votre adresse",
            pHcountry: "Veuillez entrer votre pays",
            pHcity: "Veuillez entrer votre ville",
            pHzipCode: "S'il vous plait, entrer votre code postal",
            pHstate: "Veuillez entrer votre État",
            pHIdentity: "Veuillez saisir votre numéro d'identité",
            checkIdentity: "Veuillez vérifier votre numéro d'identité",
            checkname: "Veuillez vérifier votre nom",
            checkcontactName: "Veuillez vérifier votre nom et prénom",
            checkaddress: "Veuillez vérifier votre adresse",
            checkcountry: "Veuillez vérifier votre pays",
            checkcity: "Veuillez vérifier votre ville",
            checkstate: "Veuillez vérifier votre état",
            saveButton: "Enregistrer l'adresse et continuer",
            addacard: "Ajouter une carte",
            checkCreditCard:
              "Veuillez vérifier votre numéro de carte de crédit",
            checkExpiricy: "Veuillez vérifier votre date d'expiration",
            checkCvc: "Merci de vérifier votre CV",
            checkName: "Veuillez vérifier votre nom et prénom",
            checkEmail: "Veuillez vérifier votre adresse e-mail",
            checkZipCode: "Veuillez vérifier votre code postal",
            continue: "Ajouter une carte et continuer",
            placeOrder: "Passer la commande",
            paymentMethod: "Mode de paiement",
            orderPlaced: "Commande passée",
            orderPlacedHeader: "Toutes nos félicitations!",
            orderPlacedDescription: "Votre commande a été passée avec succès.",
            next: "Continuer",
            back: "Dos",
            pleaseSelectService: "Veuillez sélectionner votre service",
            orderSummary: "Récapitulatif de la commande",
            pay: "Payer",
            orderReceived: "Votre commande a bien été reçue",
            orderStatus: "Statut de la commande",
            error: "Erreur",

            phone: "Numéro de téléphone",
            pHphone: "Veuillez entrer votre numéro de téléphone",
            checkPhone: "Veuillez vérifier votre numéro de téléphone",
            hotel: "Nom d'hôtel",
            pHhotel: "Veuillez entrer votre nom d'hôtel",
            checkHotel: "Veuillez vérifier votre nom d'hôtel",
            room: "Numéro do chambre",
            pHroom: "Veuillez entrer votre numéro do chambre",
            checkRoom: "Veuillez vérifier votre numéro do chambre",
          },
        },
      },
      ru: {
        translation: {
          terms: {
            header: "Условия и положения",
            description:
              "Спасибо, что выбрали наше веб-приложение или мобильное приложение для своих нужд в путешествии. Перед использованием наших услуг внимательно прочтите следующие условия и положения:",
            h1: "Принятие условий:",
            p1:
              "Используя наши услуги, вы соглашаетесь соблюдать настоящие Условия и положения. Если вы не согласны с настоящими Условиями и положениями, вы не можете пользоваться нашими услугами.",
            h2: "Право на использование:",
            p2:
              "Вам должно быть не менее 18 лет, чтобы пользоваться нашими услугами. Используя наши услуги, вы заявляете и гарантируете, что вам не менее 18 лет.",
            h3: "Описание услуги:",
            p3:
              "Наши веб-сервисы бронирования или мобильного приложения предоставляют платформу для подключения пользователей к одобренным TURSAB услугам, поддерживаемым туристическими агентствами. Наши услуги предназначены только для коммерческого использования.",
            h4: "Оплата и возврат средств:",
            p4:
              "Пользователи обязаны платить за услуги через нашу платформу. Мы принимаем различные формы оплаты, включая кредитные карты, дебетовые карты и электронные платежные методы. Все платежи, осуществляемые через нашу платформу, подчиняются нашей политике оплаты, которая может время от времени обновляться. Мы делаем возвраты бесплатно за 60 минут до согласованного времени получения. Мы не делаем возвраты менее чем за 60 минут до согласованного времени получения",
            h5: "Поведение пользователя :",
            p5:
              "Ожидается, что пользователи будут использовать наши услуги ответственно и не будут совершать никаких действий, которые могут нанести вред нашей платформе, сотрудникам или другим пользователям. Пользователям не разрешается использовать нашу платформу в любых незаконных или несанкционированных целях. Пользователям также запрещено вмешиваться в работу нашей платформы или заниматься любой деятельностью, которая может поставить под угрозу безопасность или целостность нашей платформы.",
            h6: "Пользовательский контент :",
            p6:
              "Пользователи несут исключительную ответственность за контент, который они отправляют через нашу платформу. Отправляя контент, пользователи предоставляют нам неисключительную, безвозмездную, передаваемую и сублицензируемую лицензию на использование, изменение и воспроизведение контента в целях предоставления наших услуг.",
            h7: "Интеллектуальная собственность :",
            p7:
              "Наша платформа, включая весь контент и интеллектуальную собственность, принадлежит нам и/или нашим лицензиарам. Пользователи не могут копировать, изменять, распространять или воспроизводить какой-либо контент или интеллектуальную собственность без нашего предварительного письменного согласия.",
            h8: "Отказ от ответственности :",
            p8:
              "Мы не гарантируем доступность, надежность или качество любых услуг, предоставляемых водителями, использующими нашу платформу. Мы не несем ответственности за любые убытки или ущерб, которые могут возникнуть в результате использования наших услуг. Наша платформа предоставляется как есть и без каких-либо гарантий, явных или подразумеваемых.",
            h9: "Ограничение ответственности :",
            p9:
              "Ни при каких обстоятельствах мы не несем ответственности за любые косвенные, случайные, особые или косвенные убытки, возникающие в результате или в связи с использованием наших услуг, независимо от того, были ли мы уведомлены о возможности таких убытков.",
            h10: "Прекращение :",
            p10:
              "Мы оставляем за собой право прекратить доступ пользователей к нашей платформе в любое время, без предварительного уведомления и по любой причине.",
            h11: "Применимое право :",
            p11:
              "Настоящие Условия регулируются и толкуются в соответствии с законами юрисдикции, в которой находится компания.",
            h12: "Поправки :",
            p12:
              "Мы оставляем за собой право обновлять настоящие Условия в любое время, без предварительного уведомления. Пользователям рекомендуется периодически просматривать настоящие Условия, чтобы быть в курсе любых изменений. Если у вас есть какие-либо вопросы или опасения по поводу настоящих Условий, свяжитесь с нами по адресу oncu@zar.com.tr",
          },
          privacy: {
            header: "Политика конфиденциальности",
            description:
              "Эта страница используется для информирования посетителей о наших политиках сбора, использования и раскрытия Персональной информации, если кто-либо решил использовать любой из наших Сервисов.",
            h1: "Принятие условий:",
            p1:
              "Если вы решите использовать наш Сервис, вы соглашаетесь на сбор и использование информации в соответствии с этой политикой. Персональная информация, которую мы собираем, используется для предоставления и улучшения Сервиса. Мы не будем использовать или передавать вашу информацию кому-либо, кроме случаев, описанных в настоящей Политике конфиденциальности. Термины, используемые в настоящей Политике конфиденциальности, имеют те же значения, что и в наших Условиях и положениях, которые доступны во всех наших приложениях, если иное не определено в настоящей Политике конфиденциальности.",
            h2: "Сбор и использование информации:",
            p2:
              "Для лучшего опыта при использовании нашего Сервиса мы потребуем от вас предоставить нам определенную персональную информацию, включая, помимо прочего, Имя, Фамилию, Адрес электронной почты, Номер телефона. Запрашиваемая нами информация будет храниться нами и использоваться, как описано в настоящей Политика конфиденциальности. Мы собираем следующую конфиденциальную информацию, когда вы используете или регистрируетесь в нашем Приложении. Мы собираем данные о вашем местоположении, чтобы приложение использовало сторонние сервисы для входа в систему через социальные сети, такие как Google и Apple Login, которые собирают информацию, используемую для вашей идентификации. Мы фиксируем адрес электронной почты и имя пользователя при входе в систему через социальные сети, если пользователь выбрал его раскрытие при выполнении входа в систему через социальные сети.",
            h3: "Данные журнала:",
            p3:
              "Мы хотим сообщить вам, что всякий раз, когда вы используете нашу Службу, в случае ошибки в приложении мы собираем данные и информацию (через сторонние продукты) на вашем телефоне, называемые данными журнала. Эти данные журнала могут включать в себя такую ​​информацию, как адрес интернет-протокола (IP) вашего устройства, имя устройства, версию операционной системы, конфигурацию приложения при использовании нашей Службы, время и дату вашего использования Службы и другую статистику.",
            h4: "Файлы cookie:",
            p4:
              "Файлы cookie — это файлы с небольшим объемом данных, которые обычно используются в качестве анонимных уникальных идентификаторов. Они отправляются в ваш браузер с веб-сайтов, которые вы посещаете, и хранятся во внутренней памяти вашего устройства. Этот Сервис не использует эти cookie явно. Однако приложение может использовать сторонний код и библиотеки, которые используют cookie для сбора информации и улучшения своих услуг. У вас есть возможность принять или отказаться от этих cookie-файлов и узнать, когда cookie-файл отправляется на ваше устройство. Если вы решите отказаться от наших cookie-файлов, вы не сможете использовать некоторые части этого Сервиса.",
            h5: "Поставщики услуг:",
            p5:
              "Мы можем нанимать сторонние компании и лиц по следующим причинам: для содействия нашему Сервису; для предоставления Сервиса от нашего имени; для оказания услуг, связанных с Сервисом; или для оказания нам помощи в анализе того, как используется наш Сервис. Мы хотим сообщить пользователям этого Сервиса, что эти третьи лица имеют доступ к вашей Персональной информации. Причина заключается в выполнении задач, возложенных на них от нашего имени. Однако они обязаны не раскрывать и не использовать информацию в каких-либо других целях.",
            h6: "Безопасность:",
            p6:
              "Мы ценим ваше доверие в предоставлении нам вашей Персональной информации, поэтому мы стремимся использовать коммерчески приемлемые средства ее защиты. Но помните, что ни один метод передачи через Интернет или метод электронного хранения не является на 100% безопасным и надежным, и мы не можем гарантировать его абсолютную безопасность.",
            h7: "Ссылка на другие сайты:",
            p7:
              "Эта Служба может содержать ссылки на другие сайты. Если вы нажмете на стороннюю ссылку, вы будете перенаправлены на этот сайт. Обратите внимание, что эти внешние сайты не управляются нами. Поэтому мы настоятельно рекомендуем вам ознакомиться с Политикой конфиденциальности этих веб-сайтов. Мы не контролируем и не несем ответственности за содержание, политику конфиденциальности или практику любых сторонних сайтов или служб.",
            h8: "Конфиденциальность детей:",
            p8:
              "Эти Службы не предназначены для лиц младше 13 лет. Мы сознательно не собираем личную информацию от детей младше 13 лет. В случае, если мы обнаруживаем, что ребенок младше 13 лет предоставил нам личную информацию, мы немедленно удаляем ее с наших серверов. Если вы являетесь родителем или опекуном и вам известно, что ваш ребенок предоставил нам личную информацию, свяжитесь с нами, чтобы мы могли предпринять необходимые действия.",
            h9: "Удалить учетную запись",
            p9:
              "Пользователь может удалить все свои данные из системы, удалив учетную запись со страницы профиля в мобильном и веб-приложении. После удаления учетной записи все ваши данные будут удалены из системы. Изображение вашего профиля, адрес электронной почты, номер телефона, логины социальных сетей, включая логин Google, и вся история бронирования — все это будет удалено навсегда. Удаленные данные пользователя и учетная запись не подлежат восстановлению.",
            h10: "Изменения в этой Политике конфиденциальности :",
            p10:
              "Мы можем время от времени обновлять нашу Политику конфиденциальности. Поэтому вам рекомендуется периодически просматривать эту страницу на предмет любых изменений. Мы сообщим вам о любых изменениях, разместив новую Политику конфиденциальности на этой странице. Эта политика вступает в силу с 01.01.2025",
            h11: "Свяжитесь с нами :",
            p11:
              "Если у вас есть какие-либо вопросы или предложения по нашей Политике конфиденциальности, не стесняйтесь обращаться к нам по адресу oncu@zar.com.tr.",
          },
          menu: {
            home: "Дом",
            terms: "условия и положения",
          },
          product: {
            viewDetails: "Посмотреть детали",
            priceFrom: "от ",
            May: "Май",
            June: "Июнь",
            July: "Июль",
            August: "Август",
            September: "Сентябрь",
            October: "Октябрь",
          },
          reservation: {
            bookingMessage: "Запрос на бронирование от Bodrum.boats",
            makeareservation: "Зарезервировать",
            startDate: "Дата начала",
            endDate: "Дата окончания",
            tourDate: "Дата тура",
            tourName: "Название тура",
            tourType: "Тип тура",
            tourPrice: "Стоимость тура",
            adults: "Взрослые",
            children: "Дети",
            guests: "Гости",
            bookNow: "Забронируйте сейчас через WhatsApp!",
            ischarter: "Устав?",
            person: "Человек",
            addBasket: "Добавить в корзину",
            pleaseSelect: "Пожалуйста выберите",
            payWhatsapp: "Полный заказ по WhatsApp",
            payDeposit: "Оплатить депозит с помощью кредитной карты",
            payAll: "Оплата через кредитную карту",
            deposit: "Депозит",
          },
          amenities: {
            boatDetails: "Детали лодки",
            boatType: "Тип лодки",
            lenght: "Длина",
            numberofCabins: "Количество кают",
            engine: "Двигатель",
          },
          pictures: {
            viewPhotos: "Посмотреть фотографии",
          },
          topBar: {
            overview: "Обзор",
            details: "Подробности",
            reservation: "Бронирование",
            boatDetails: "Детали лодки",
            boatPrices: "Цены на лодки",
            contactUs: "Связаться с нами",
            share: "Делиться",
          },
          tour: {
            prices: "Цены",
          },
          checkout: {
            cardHolder: "Держатель карты",
            cardNumber: "Номер карты",
            expires: "Срок действия истекает",
            month: " Месяц",
            year: "Год",
            fullName: "ИМЯ ФАМИЛИЯ",
            tourInformation: "Информация о туре",
            basicInformation: "Основная информация",
            billingInformation: "Платежная информация",
            paymentInformation: "Платежная информация",
            identity: "Идентификационный номер",
            date: "Дата тура",
            name: "Имя",
            surname: "Фамилия",
            contactName: "Имя и фамилия",
            email: "Электронная почта",
            address: "Адрес",
            country: "Страна",
            city: "Город",
            state: "Состояние",
            zipCode: "Почтовый индекс",
            pHcontactName: "Пожалуйста, введите ваше имя и фамилию",
            pHname: "Пожалуйста, введите Ваше имя ",
            pHdate: "Пожалуйста, выберите дату тура ",
            pHsurname: "Пожалуйста, введите вашу фамилию",
            pHemail: "Пожалуйста, введите адрес электронной почты",
            pHaddress: "Пожалуйста, введите свой адрес",
            pHcountry: "Пожалуйста, введите вашу страну",
            pHcity: "Пожалуйста, введите ваш город",
            pHzipCode: "Пожалуйста, введите ваш почтовый индекс",
            pHstate: "Пожалуйста, укажите ваш штат",
            pHIdentity: "Пожалуйста, введите свой идентификационный номер",
            checkIdentity: "Пожалуйста, проверьте свой идентификационный номер",
            checkname: "Пожалуйста, проверьте свое имя",
            checkcontactName: "Пожалуйста, проверьте свое имя и фамилию",
            checkaddress: "Пожалуйста, проверьте свой адрес",
            checkcountry: "Пожалуйста, проверьте вашу страну",
            checkcity: "Пожалуйста, проверьте свой город",
            checkstate: "Пожалуйста, проверьте свой штат",
            saveButton: "Сохранить адрес и продолжить",
            addacard: "Добавить карту",
            checkCreditCard:
              "Пожалуйста, проверьте номер вашей кредитной карты",
            checkExpiricy: "Пожалуйста, проверьте срок годности",
            checkCvc: "Пожалуйста, проверьте свой CVC",
            checkName: "Пожалуйста, проверьте свое имя и фамилию",
            checkEmail: "Пожалуйста, проверьте свой адрес электронной почты",
            checkZipCode: "Пожалуйста, проверьте свой почтовый индекс",
            continue: "Добавить карту и продолжить",
            placeOrder: "Разместить заказ",
            paymentMethod: "Способ оплаты",
            orderPlaced: "Заказ размещен",
            orderPlacedHeader: "Поздравляем!",
            orderPlacedDescription: "Ваш заказ успешно размещен.",
            next: "Продолжать",
            back: "Назад",
            pleaseSelectService: "Пожалуйста, выберите вашу услугу",
            orderSummary: "итог заказа",
            pay: "Платить",
            orderReceived: "Ваш заказ был размещен",
            orderStatus: "Статус заказа",
            error: "Ошибка",
            phone: "Номер телефона",
            pHphone: "Пожалуйста, введите свой телефон",
            checkPhone: "Пожалуйста, проверьте свой номер телефона",
            hotel: "Название отеля",
            pHhotel: "Пожалуйста, введите название вашего отеля",
            checkHotel: "Пожалуйста, проверьте название вашего отеля",
            room: "Номер комнаты",
            pHroom: "Пожалуйста, введите номер вашей комнаты",
            checkRoom: "Пожалуйста, проверьте номер вашей комнаты",
          },
        },
      },
      ar: {
        translation: {
          terms: {
            header: "الشروط والأحكام",
            description:
              "نشكرك على اختيار تطبيقنا على الويب أو الهاتف المحمول لتلبية احتياجاتك المتعلقة بالسفر. يُرجى قراءة الشروط والأحكام التالية بعناية قبل استخدام خدماتنا:",
            h1: "قبول الشروط :",
            p1:
              "باستخدام خدماتنا، فإنك توافق على الالتزام بهذه الشروط والأحكام. إذا كنت لا توافق على هذه الشروط والأحكام، فلا يجوز لك استخدام خدماتنا.",
            h2: "الأهلية :",
            p2:
              "يجب أن يكون عمرك 18 عامًا على الأقل لاستخدام خدماتنا. باستخدام خدماتنا، فإنك تقر وتضمن أنك تبلغ من العمر 18 عامًا على الأقل.",
            h3: "وصف الخدمة :",
            p3:
              "توفر خدمات الحجز على الويب أو الهاتف المحمول منصة لربط المستخدمين بخدمات معتمدة من TURSAB ومدعومة من وكالات السفر. خدماتنا مخصصة للاستخدام التجاري فقط.",
            h4: "الدفع والاسترداد:",
            p4:
              "يتعين على المستخدمين الدفع مقابل الخدمات المقدمة من خلال منصتنا. نحن نقبل أشكالًا مختلفة من الدفع، بما في ذلك بطاقات الائتمان وبطاقات الخصم وطرق الدفع الإلكترونية. تخضع جميع المدفوعات التي تتم من خلال منصتنا لسياسات الدفع الخاصة بنا، والتي قد يتم تحديثها من وقت لآخر. نقوم برد الأموال مجانًا حتى 60 دقيقة قبل وقت الاستلام المتفق عليه. لا نقوم برد الأموال قبل أقل من 60 دقيقة من وقت الاستلام المتفق عليه",
            h5: "سلوك المستخدم :",
            p5:
              "من المتوقع أن يستخدم المستخدمون خدماتنا بمسؤولية وعدم الانخراط في أي سلوك قد يضر بمنصتنا أو موظفينا أو المستخدمين الآخرين. لا يُسمح للمستخدمين باستخدام منصتنا لأي غرض غير قانوني أو غير مصرح به. يُحظر على المستخدمين أيضًا التدخل في تشغيل منصتنا أو الانخراط في أي نشاط قد يعرض أمن منصتنا أو سلامتها للخطر.",
            h6: "محتوى المستخدم :",
            p6:
              "المستخدمون مسؤولون وحدهم عن المحتوى الذي يرسلونه من خلال منصتنا. من خلال إرسال المحتوى، يمنحنا المستخدمون ترخيصًا غير حصري وخالٍ من حقوق الملكية وقابل للتحويل وقابل للترخيص من الباطن لاستخدام المحتوى وتعديله وإعادة إنتاجه لغرض تقديم خدماتنا.",
            h7: "الملكية الفكرية :",
            p7:
              "منصتنا، بما في ذلك كل المحتوى والملكية الفكرية، مملوكة لنا و/أو لمرخصينا. لا يجوز للمستخدمين نسخ أو تعديل أو توزيع أو إعادة إنتاج أي من المحتوى أو الملكية الفكرية دون موافقتنا الكتابية المسبقة.",
            h8: "إخلاء المسؤولية :",
            p8:
              "لا نضمن توافر أو موثوقية أو جودة أي خدمات يقدمها السائقون الذين يستخدمون منصتنا. نحن لسنا مسؤولين عن أي خسارة أو ضرر قد ينتج عن استخدام خدماتنا. يتم توفير منصتنا كما هي ودون أي ضمانات، صريحة أو ضمنية.",
            h9: "حدود المسؤولية :",
            p9:
              "لن نكون مسؤولين في أي حال من الأحوال عن أي أضرار غير مباشرة أو عرضية أو خاصة أو تبعية ناشئة عن أو فيما يتعلق باستخدام خدماتنا، سواء تم إخطارنا بإمكانية حدوث مثل هذه الأضرار أم لا.",
            h10: "إنهاء :",
            p10:
              "نحتفظ بالحق في إنهاء وصول المستخدم إلى منصتنا في أي وقت، دون إشعار، لأي سبب.",
            h11: "القانون الحاكم :",
            p11:
              "تخضع هذه الشروط والأحكام لقوانين الولاية القضائية التي يقع مقر الشركة فيها ويتم تفسيرها وفقًا لها.",
            h12: "التعديلات :",
            p12:
              "نحتفظ بالحق في تحديث هذه الشروط والأحكام في أي وقت، دون إشعار. ننصح المستخدمين بمراجعة هذه الشروط والأحكام بشكل دوري للبقاء على اطلاع بأي تغييرات. إذا كانت لديك أي أسئلة أو مخاوف بشأن هذه الشروط والأحكام، يرجى الاتصال بنا على oncu@zar.com.tr",
          },
          privacy: {
            eader: "سياسة الخصوصية",
            description:
              "تُستخدم هذه الصفحة لإعلام الزوار بشأن سياساتنا فيما يتعلق بجمع واستخدام والإفصاح عن المعلومات الشخصية إذا قرر أي شخص استخدام أي من خدماتنا.",
            h1: "قبول الشروط :",
            p1:
              "إذا اخترت استخدام خدمتنا، فإنك توافق على جمع واستخدام المعلومات فيما يتعلق بهذه السياسة. تُستخدم المعلومات الشخصية التي نجمعها لتوفير الخدمة وتحسينها. لن نستخدم معلوماتك أو نشاركها مع أي شخص باستثناء ما هو موضح في سياسة الخصوصية هذه. المصطلحات المستخدمة في سياسة الخصوصية هذه لها نفس المعاني الواردة في شروطنا وأحكامنا، والتي يمكن الوصول إليها في جميع تطبيقاتنا ما لم يتم تعريفها بخلاف ذلك في سياسة الخصوصية هذه.",
            h2: "جمع المعلومات واستخدامها:",
            p2:
              "للحصول على تجربة أفضل، أثناء استخدام خدمتنا، سنطلب منك تزويدنا بمعلومات تعريف شخصية معينة، بما في ذلك على سبيل المثال لا الحصر الاسم الأول واسم العائلة وعنوان البريد الإلكتروني ورقم الهاتف. سنحتفظ بالمعلومات التي نطلبها وسنستخدمها كما هو موضح في سياسة الخصوصية هذه. نقوم بجمع المعلومات الحساسة التالية عند استخدامك أو تسجيلك في تطبيقنا. نقوم بجمع بيانات موقعك لتمكين التطبيق من استخدام خدمات الجهات الخارجية لتسجيل الدخول عبر وسائل التواصل الاجتماعي مثل تسجيل الدخول عبر Google وApple التي تجمع المعلومات المستخدمة لتحديد هويتك. نقوم بجمع بريد المستخدم الإلكتروني والاسم من تسجيل الدخول عبر وسائل التواصل الاجتماعي إذا اختار المستخدم الكشف عنه أثناء إجراء تسجيل الدخول عبر وسائل التواصل الاجتماعي.",
            h3: "بيانات السجل:",
            p3:
              "نريد أن نخبرك أنه كلما استخدمت خدمتنا، في حالة حدوث خطأ في التطبيق، نقوم بجمع البيانات والمعلومات (من خلال منتجات الجهات الخارجية) على هاتفك والتي تسمى بيانات السجل. قد تتضمن بيانات السجل هذه معلومات مثل عنوان بروتوكول الإنترنت IP الخاص بجهازك، واسم الجهاز، وإصدار نظام التشغيل، وتكوين التطبيق عند استخدام خدمتنا، ووقت وتاريخ استخدامك للخدمة، وإحصائيات أخرى.",
            h4: "ملفات تعريف الارتباط:",
            p4:
              "ملفات تعريف الارتباط هي ملفات تحتوي على كمية صغيرة من البيانات تُستخدم عادةً كمعرفات فريدة مجهولة. يتم إرسالها إلى متصفحك من مواقع الويب التي تزورها ويتم تخزينها على الذاكرة الداخلية لجهازك. لا تستخدم هذه الخدمة ملفات تعريف الارتباط هذه صراحةً. ومع ذلك، قد يستخدم التطبيق أكوادًا ومكتبات تابعة لجهات خارجية تستخدم ملفات تعريف الارتباط لجمع المعلومات وتحسين خدماتها. لديك خيار قبول ملفات تعريف الارتباط هذه أو رفضها ومعرفة متى يتم إرسال ملف تعريف ارتباط إلى جهازك. إذا اخترت رفض ملفات تعريف الارتباط الخاصة بنا، فقد لا تتمكن من استخدام بعض أجزاء هذه الخدمة.",
            h5: "مقدمو الخدمة:",
            p5:
              "قد نوظف شركات وأفرادًا تابعين لجهات خارجية للأسباب التالية: لتسهيل خدمتنا؛ لتقديم الخدمة نيابة عنا؛ لأداء خدمات متعلقة بالخدمة؛ أو لمساعدتنا في تحليل كيفية استخدام خدمتنا. نريد إبلاغ مستخدمي هذه الخدمة بأن هذه الجهات الخارجية لديها حق الوصول إلى معلوماتك الشخصية. والسبب هو أداء المهام الموكلة إليهم نيابة عنا. ومع ذلك، فهم ملزمون بعدم الكشف عن المعلومات أو استخدامها لأي غرض آخر.",
            h6: "الأمان:",
            p6:
              "نحن نقدر ثقتك في تزويدنا بمعلوماتك الشخصية، وبالتالي نسعى جاهدين لاستخدام وسائل مقبولة تجاريًا لحمايتها. ولكن تذكر أنه لا توجد طريقة نقل عبر الإنترنت أو طريقة تخزين إلكتروني آمنة وموثوقة بنسبة 100%، ولا يمكننا ضمان أمانها المطلق.",
            h7: "رابط إلى مواقع أخرى:",
            p7:
              "قد تحتوي هذه الخدمة على روابط لمواقع أخرى. إذا نقرت على رابط تابع لجهة خارجية، فسيتم توجيهك إلى هذا الموقع. لاحظ أن هذه المواقع الخارجية لا يتم تشغيلها من قبلنا. لذلك، ننصحك بشدة بمراجعة سياسة الخصوصية الخاصة بهذه المواقع. ليس لدينا أي سيطرة ولا نتحمل أي مسؤولية عن محتوى أو سياسات الخصوصية أو ممارسات أي مواقع أو خدمات تابعة لجهات خارجية.",
            h8: "خصوصية الأطفال:",
            p8:
              "لا تستهدف هذه الخدمات أي شخص يقل عمره عن 13 عامًا. ولا نجمع عن قصد معلومات تعريف شخصية من الأطفال الذين تقل أعمارهم عن 13 عامًا. وفي حالة اكتشافنا أن طفلًا يقل عمره عن 13 عامًا قد زودنا بمعلومات شخصية، فإننا نحذفها على الفور من خوادمنا. إذا كنت أحد الوالدين أو الوصي وكنت على علم بأن طفلك قد زودنا بمعلومات شخصية، فيرجى الاتصال بنا حتى نتمكن من اتخاذ الإجراءات اللازمة.",

            h9: "حذف الحساب",
            p9:
              "يمكن للمستخدم إزالة جميع بياناته من النظام عن طريق حذف الحساب من صفحة الملف الشخصي في تطبيق الهاتف المحمول والويب. بمجرد حذف حسابك، سيتم مسح جميع بياناتك من النظام. صورة ملفك الشخصي والبريد الإلكتروني ورقم الهاتف وتسجيلات الدخول الاجتماعية بما في ذلك تسجيل الدخول إلى Google وجميع سجلات الحجز، سيتم إزالة كل شيء بشكل دائم. بيانات المستخدم والحساب المحذوف غير قابلين للاسترداد.",
            h10: "تغييرات على سياسة الخصوصية هذه :",
            p10:
              "قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. وبالتالي، ننصحك بمراجعة هذه الصفحة بشكل دوري لمعرفة أي تغييرات. وسنقوم بإخطارك بأي تغييرات من خلال نشر سياسة الخصوصية الجديدة على هذه الصفحة. تسري هذه السياسة اعتبارًا من 01-01-2025",
            h11: "اتصل بنا :",
            p11:
              "إذا كانت لديك أي أسئلة أو اقتراحات حول سياسة الخصوصية الخاصة بنا، فلا تتردد في الاتصال بنا على oncu@zar.com.tr.",
          },
          menu: {
            home: "بيت",
            terms: "الشروط والأحكام",
          },
          product: {
            viewDetails: "عرض التفاصيل",
            priceFrom: "من ",
            May: "مايو",
            June: "يونيو",
            July: "يوليو",
            August: "أغسطس",
            September: "سبتمبر",
            October: "أكتوبر",
          },
          reservation: {
            bookingMessage: "طلب الحجز من Bodrum.boats",
            makeareservation: "احجز",
            startDate: "تاريخ البدء",
            endDate: "تاريخ الانتهاء",
            tourDate: "تاريخ الجولة",
            tourName: "اسم الجولة",
            tourType: "نوع الجولة",
            tourPrice: "سعر الجولة",
            adults: "الكبار",
            children: "أطفال",
            guests: "ضيوف",
            bookNow: "احجز الآن عبر الواتس اب!",
            ischarter: "الميثاق؟",
            person: "شخص",
            addBasket: "اضف الى السلة",
            pleaseSelect: "الرجاء التحديد",
            payWhatsapp: "استكمال الطلب عن طريق الواتس اب",
            payDeposit: "دفع الوديعة عن طريق بطاقة الائتمان",
            payAll: "الدفع عن طريق بطاقة الائتمان",
            deposit: "إيداع",
          },
          amenities: {
            boatDetails: "تفاصيل القارب",
            boatType: "نوع القارب",
            lenght: "الطول",
            numberofCabins: "عدد الكبائن",
            engine: "محرك",
          },
          pictures: {
            viewPhotos: "عرض الصور",
          },
          topBar: {
            overview: "ملخص",
            details: "تفاصيل",
            reservation: "حجز",
            boatDetails: "تفاصيل القارب",
            boatPrices: "أسعار القوارب",
            contactUs: "اتصل بنا",
            share: "يشارك",
          },
          tour: {
            prices: "الأسعار",
          },
          checkout: {
            cardHolder: "حامل البطاقة",
            cardNumber: "رقم البطاقة",
            expires: "تنتهي",
            month: " شهر",
            year: "سنة",
            fullName: "اسم اللقب",
            tourInformation: "معلومات الجولة",
            basicInformation: "معلومات اساسية",
            billingInformation: "معلومات الفواتير",
            paymentInformation: "معلومات الدفع",
            identity: "رقم الهوية",
            date: "تاريخ الجولة",
            name: "اسم",
            surname: "اسم العائلة",
            contactName: "اسم ولقب",
            email: "بريد إلكتروني",
            address: "عنوان",
            country: "دولة",
            city: "مدينة",
            state: "ولاية",
            zipCode: "الرمز البريدي",
            pHcontactName: "الرجاء إدخال اسمك ولقبك",
            pHname: "من فضلك أدخل إسمك ",
            pHdate: "الرجاء تحديد تاريخ الجولة ",
            pHsurname: "الرجاء إدخال اللقب الخاص بك",
            pHemail: "الرجاء إدخال عنوان البريد الإلكتروني",
            pHaddress: "الرجاء إدخال عنوانك",
            pHcountry: "الرجاء إدخال بلدك",
            pHcity: "الرجاء إدخال مدينتك",
            pHzipCode: "الرجاء إدخال الرمز البريدي الخاص بك",
            pHstate: "الرجاء إدخال ولايتك",
            pHIdentity: "الرجاء إدخال رقم الهوية الخاص بك",
            checkIdentity: "يرجى التحقق من رقم الهوية الخاصة بك",
            checkname: "يرجى التحقق من اسمك",
            checkcontactName: "يرجى التحقق من اسمك ولقبك",
            checkaddress: "يرجى التحقق من عنوانك",
            checkcountry: "يرجى التحقق من بلدك",
            checkcity: "يرجى التحقق من مدينتك",
            checkstate: "يرجى التحقق من ولايتك",
            saveButton: "احفظ العنوان وتابع",
            addacard: "إضافة بطاقة",
            checkCreditCard: "يرجى التحقق من رقم بطاقة الائتمان الخاصة بك",
            checkExpiricy: "يرجى التحقق من تاريخ انتهاء الصلاحية الخاص بك",
            checkCvc: "يرجى التحقق من cvc الخاص بك",
            checkName: "يرجى التحقق من اسمك ولقبك",
            checkEmail: "يرجى التحقق من عنوان البريد الإلكتروني الخاص بك",
            checkZipCode: "يرجى التحقق من الرمز البريدي الخاص بك",
            continue: "أضف البطاقة وتابع",
            placeOrder: "مكان الامر",
            paymentMethod: "طريقة الدفع او السداد",
            orderPlaced: "تم الطلب",
            orderPlacedHeader: "تهانينا!",
            orderPlacedDescription: "لقد تم تقديم طلبك بنجاح.",
            next: "يكمل",
            back: "خلف",
            pleaseSelectService: "الرجاء تحديد الخدمة الخاصة بك",
            orderSummary: "ملخص الطلب",
            pay: "يدفع",
            orderReceived: "وقد وضعت طلبك",
            orderStatus: "حالة الطلب",
            error: "خطأ",

            phone: "رقم التليفون",
            pHphone: "الرجاء إدخال هاتفك",
            checkPhone: "يرجى التحقق من رقم هاتفك",
            hotel: "اسم فندق",
            pHhotel: "الرجاء إدخال اسم الفندق الخاص بك",
            checkHotel: "يرجى التحقق من اسم الفندق الخاص بك",
            room: "رقم الغرفة",
            pHroom: "الرجاء إدخال رقم الغرفة الخاصة بك",
            checkRoom: "يرجى التحقق من رقم غرفتك",
          },
        },
      },
    },
  });

export default i18n;
