import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "themes/default.theme";
import GlobalStyles from "themes/global.style";
import AuthProvider from "context/AuthProvider";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Routes from "./router";
import "./i18n";

const App = () => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      <BrowserRouter>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </BrowserRouter>
    </>
    {/* <TawkMessengerReact
      propertyId="5c70723e3341d22d9ce5b02b"
      widgetId="default"
    /> */}
  </ThemeProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));
